import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Div from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import axios from "axios"
import { useEffect, useState } from "react";
import {useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import PropTypes from "prop-types";
import  styles from './login.module.css';
import Image from 'react-bootstrap/Image';
import  Logo from '../images/LOGO5.png';
import { IoClose } from "react-icons/io5";
import { useFormik } from 'formik';
export default function Login({setToken,toggle}) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const [seen, setSeen] = useState(false)
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }
 
    /*const handleSubmit =  async (event) => {
        event.preventDefault();
           let axiosConfig = {
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      
            }
          };
        await axios.post('https://whoozhaf.000webhostapp.com/API/users.php/login', inputs,axiosConfig).then(function(response){
            console.log(response.data);
            if(response.data['status']===0){
                setShow(true);
                
            }
            else{
           
                setToken(response.data['userID']);

                navigate('/');
                window.location.reload()
                
             
            }
            
        });
        
    }*/
    const formik = useFormik({
      initialValues:{
        email:'',
        password:''

      },
       onSubmit:values=>{
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };

      axios.post('http://localhost/API/users.php/login', values,axiosConfig).then(function(response){
          console.log(response.data['status']);
         
          if(response.data['status']===0){
            setShow(true);
            
        }
        else{
       
         setToken(response.data['userID']);

          toggle();
        //navigate('/profile');
         window.location.reload()
            
            
         
        }

          
          
      });
  
    },
      validate:values=>{
        let errors = {}
   
        if(!values.password){
          errors.password = 'required'
        }
     
        if(!values.email){
          errors.email = 'required'
        }
    
       
        
        return errors
      }
    })
     
  return (
      
  
    <div className = {styles.container} >
          {
              show &&
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap!!</Alert.Heading>
                <p>
                Wrong Email or Password.
                </p>
                </Alert>
              
          }
          <Container>
          <Row className="justify-content-md-center">
              
              <Col md="5">
              <Card className={styles.card} >
              
             
              <Div variant="top" md="1" className={styles.formHeader}> 
                    <Card.Text className={styles.formHeadertext} onClick={toggle}>
                    <IoClose />
                    </Card.Text>
                        
              </Div>
              <Card.Body>
            
              <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="formBasicEmail" className={styles.formGroup}>
                   
                    <Form.Label className={styles.txtsignin}>Sign In</Form.Label>
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail" className={styles.formGroup+" "+styles.txtemail}>
                    
                    <Form.Control type="email" placeholder="Email" name ="email" onChange={formik.handleChange} className={styles.formTextbox} value = {formik.values.email} onBlur={formik.handleBlur} />
                    <Form.Label className={styles.lblvalidation}>  {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Form.Label>
                    </Form.Group>

                    <Form.Group  controlId="formBasicPassword" className={styles.formGroup}>
                    
                    <Form.Control type="password" placeholder="Password"  name ="password" onChange={formik.handleChange} className={styles.formTextbox} value = {formik.values.password} onBlur={formik.handleBlur} />
                    <Form.Label className={styles.lblvalidation}>  {formik.touched.password && formik.errors.password ? <div>{formik.errors.password}</div> : null}</Form.Label>
                    <Nav.Link href="/register" className={styles.forgotpassword}>Forgot Password.</Nav.Link>
                    </Form.Group>
                    <Form.Group className="mb-3"  style={{textAlign:'center'}}>
                        <Button type="submit"  className={styles.btnloginForm +' '+styles.btnLogin}>
                            Login
                        </Button>
                   
                    </Form.Group>
                  
                    <Form.Group className="mb-3" style={{textAlign:'center'}}>
                       
                 
                   
                    <Nav.Link href="/register" className={styles.btnRegister}>Create new account.</Nav.Link>
                  
                    </Form.Group>
                </Form>
                </Card.Body>    
                </Card>
              </Col>
              
          </Row>
               
          </Container>
      </div>
 

  );
}
Login.prototype = {
    setToken:PropTypes.func.isRequired
}
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiEye,HiMinusCircle,HiPlusCircle } from "react-icons/hi";
import { useEffect, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios"
import styles from "./Post.module.css";
import Form from 'react-bootstrap/Form'
import PostComments from './Comments'
import FeedbackContent from './FeedbackContent';

function Feedback({token}) {
  const [users, setUsers] = useState([]);
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);

  function handleClick()
    {
      setproisActive(current=>!current);
      }
  
 function getUsers(token) {
        axios.get(`https://whoozhaf.000webhostapp.com/API/users.php/${token}/profile`).then(function(response) {
           
            setUsers([response.data]);

        });
    }
      useEffect(() => {
          const onScroll = () => setOffset(window.pageYOffset);
          getUsers(token);
          if(offset>0){
            setScroll(true); 
         
          }
          else{
             setScroll(false); 
          }
          
          // clean up code
          window.removeEventListener('scroll', onScroll);
          window.addEventListener('scroll', onScroll, { passive: true });
          return () => window.removeEventListener('scroll', onScroll);
      }, [offset]);
  return (
    <>
    <Container fluid="md" className={styles.container}>

      <FeedbackContent token={token}></FeedbackContent>
   
 
       
   
    </Container>
   
     </>
  );
}

export default Feedback;
import styles from './Navigationbar.module.css';
import {Container,Button,Form} from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Image from 'react-bootstrap/Image';
import  Logo from '../images/icon.png';
import { MdSearch } from "react-icons/md";
import {FaEdit} from "react-icons/fa";
import CreateModal  from './CreateModal'
import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import {useSelector,useDispatch} from 'react-redux';
//import {openModal} from '../features/counter/show';
import {showModal} from '../features/showModal';
function Navigationbar({token}) {
  //const tokenNav = useToken.token;
  //const [show,setShow] = useState(false);
  
   const logout = ()=>{
   
    sessionStorage.clear()
  }
  //const showModal = ()=>{setShow(true);}



  const dispatch = useDispatch();
  const handleShow = () => dispatch(showModal());
  return (
    <>
    
    <Navbar collapseOnSelect  expand="lg" fixed='top' className={styles.nabvar} >
      <Container>
        <Navbar.Brand href="#home"><Image  src={Logo} className={styles.logo} rounded/></Navbar.Brand>
        <Button type = "button" className={!token?styles.searchNone:styles.btnCreate2} onClick={handleShow}>
            <FaEdit className={styles.iconCreate}/>Create a Debate
          </Button> 
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav" className={styles.toogle}>
          <Nav className="me-auto">
          <Nav.Link  className={styles.navlinkLogout}>Alpha V1.0</Nav.Link>
            <Nav.Link href="/" className={styles.navlinkLogout}>Home</Nav.Link>
            {!token?<Nav.Link className={styles.navlinkLogout} href="/register">Sign-Up</Nav.Link>:<Nav.Link className={styles.navlinkLogout} href="/profile">Profile</Nav.Link>
            }
          
              <Form className={!token?styles.searchNone+" "+"d-flex":styles.searchBox+" "+"d-flex"} >
            <Form.Control type="search" className = {styles.searchBoxControl} placeholder="Search" aria-label="Search"
            />
            <Button variant="primary" className={styles.btnSearch}><MdSearch/></Button>
          </Form>
          
            <Button type = "button" className={!token?styles.searchNone:styles.btnCreate} onClick={handleShow}>
            <FaEdit className={styles.iconCreate}/>Create a Debate
          </Button> 

      
            {!token?<Nav.Link href="/login" className={styles.navlinkLogout}>Login</Nav.Link>:<Nav.Link href="/" onClick={logout} className={styles.navlinkLogout}>Logout</Nav.Link>
            }
           
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
 np
    <CreateModal />
    </>
  );
}

export default Navigationbar;
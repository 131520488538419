import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Div from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';
import {useNavigate,useParaams} from 'react-router-dom';
import { useState } from "react";
import axios from 'axios';
import Nav from 'react-bootstrap/Nav';
import  styles from './Register.module.css';
import  Logo from '../images/icon.png';
import Image from 'react-bootstrap/Image';
import Alert from 'react-bootstrap/Alert';
import { IoClose } from "react-icons/io5";
import { useFormik } from 'formik';
function Register({setToken,toggle}) {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const [show,setShow] = useState(false);
    //const handleChange = (event) => {
       /* const name = event.target.name;
        const value = event.target.value;
        
        setInputs(values => ({...values, [name]: value}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
       
     
          let axiosConfig = {
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      
            }
          };
  
        axios.post('https://whoozhaf.000webhostapp.com/API/users.php/register', inputs,axiosConfig).then(function(response){
            console.log(response.data);
            setShow(true);
            
        });
        navigate('/profile');
    }*/
    const formik = useFormik({
      initialValues:{
        firstname:'',
        lastname:'',
        email:'',
        password:'',
        confirmpassword:''

      },
      
       onSubmit:values=>{
        
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        console.log(values);
      axios.post('hhttp://localhost/API/users.php/register', values,axiosConfig).then(function(response){
          console.log(response.data);
          setShow(true);
          toggle();
          
          setTimeout(function() {
            navigate('/');
     
          
          }, 1000);
      });
     
      
    },
      validate:values=>{
        let errors = {}
        if(!values.firstname){
          errors.firstname = 'required'
        }
        if(!values.lastname){
          errors.lastname = 'required'
        }
        if(!values.password){
          errors.password = 'required'
        }
        if(!values.confirmpassword){
          errors.confirmpassword = 'required'
        }else if(values.password!=values.confirmpassword){
          errors.confirmpassword = 'password dont match'
        }
        if(!values.email){
          errors.email = 'required'
        }
        else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors
      }
    })
  return (

    
      <div className = {styles.container} >
             {
              show &&
                <Alert variant="success" onClose={() => setShow(false)} dismissible>
                <Alert.Heading></Alert.Heading>
                <p>
                Successfully Registered.
                </p>
                </Alert>
              
          }
           <Container>
          <Row className="justify-content-md-center">
              
              <Col md="5">
              <Card className={styles.card} >
    
              <Div variant="top" md="1" className={styles.formHeader}> 
                    <Card.Text className={styles.formHeadertext} onClick={toggle}>
                    <IoClose />
                    </Card.Text>
                        
              </Div>
              <Card.Body >
             
              <Form onSubmit={formik.handleSubmit}>
              <Form.Group controlId="formBasicEmail" className={styles.formGroup}>
                   
                   <Form.Label className={styles.txtsignin}>Sign Up</Form.Label>
                   </Form.Group>
                    <Form.Group className={styles.formGroup} controlId="formBasicEmail">
                   
                    <Form.Control type="email"  placeholder="Enter email" className={styles.formTextbox} name = "email" onChange={formik.handleChange} value = {formik.values.email} onBlur={formik.handleBlur}/>
                    
                    <Form.Label className={styles.lblvalidation}>{formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}</Form.Label>
                    </Form.Group>

                    <Form.Group className={styles.formGroup} controlId="formFname" >
                    
                    <Form.Control type="text"   placeholder="First Name" className={styles.formTextbox} name = "firstname" onChange={formik.handleChange} value = {formik.values.firstname} onBlur={formik.handleBlur}/>
                  
                    <Form.Label className={styles.lblvalidation}>  {formik.touched.firstname && formik.errors.firstname ? <div>{formik.errors.firstname}</div> : null}</Form.Label>
                    </Form.Group>

                    <Form.Group className={styles.formGroup} controlId="formLname">
                    
                    <Form.Control type="text" placeholder="Last Name" className={styles.formTextbox} name = "lastname" onChange={formik.handleChange} value = {formik.values.lastname} onBlur={formik.handleBlur}/>
                    <Form.Label className={styles.lblvalidation}>{formik.touched.lastname && formik.errors.lastname ? <div>{formik.errors.lastname}</div> : null}</Form.Label>
                    </Form.Group>

                    <Form.Group className={styles.formGroup} controlId="formBasicPassword" >
                    
                    <Form.Control type="password" placeholder="Password" className={styles.formTextbox} name = "password" onChange={formik.handleChange} value = {formik.values.password} onBlur={formik.handleBlur}/>
                    <Form.Label className={styles.lblvalidation}>{formik.touched.password && formik.errors.password ? <div>{formik.errors.password}</div> : null}</Form.Label>
                  
                    </Form.Group>
                    <Form.Group className={styles.formGroup} controlId="formBasicPassword" >
                    
                    <Form.Control type="password" placeholder="Confirm Password" className={styles.formTextbox} name = "confirmpassword" onChange={formik.handleChange}/>
                <Form.Label className={styles.lblvalidation}>{formik.touched.confirmpassword && formik.errors.confirmpassword ? <div>{formik.errors.confirmpassword}</div> : null}</Form.Label>
                    </Form.Group>
                   
                    <Form.Group className="mb-3"  style={{textAlign:'center'}}>
                        <Button type="submit"  className={styles.btnloginForm +' '+styles.btnLogin}>
                            Submit
                        </Button>
                   
                    </Form.Group>
                  
                    <Form.Group className="mb-3" style={{textAlign:'center'}}>
                       
                 
                   
                    <Nav.Link href="/register" className={styles.btnRegister}>Already have an account?</Nav.Link>
                  
                    </Form.Group>
                   
                
                </Form>
                </Card.Body>    
                </Card>
              </Col>
          </Row>
               
</Container>
      </div>


  );
}

export default Register;
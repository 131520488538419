import { useContext, useEffect, useState,useMemo } from 'react';
import axios from 'axios';
 function RepliesCount(props){
    const [replyCount,setReplyCount] = useState(0);
    function RepCount(ID){
        let axiosConfig = {
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      
            }
          };
          axios.get(`http://localhost/API/replies.php/${ID}/likescount`,axiosConfig).then(function(response){
            
           
            
            //count = response.data['count'];
            
            if(response.data>0){ 
              setReplyCount(response.data);
            }
          
          });
          return (replyCount);
    }
   
    return (
        <>{RepCount(props.commentID)}</>
        
    );
    
    }
    export default RepliesCount;


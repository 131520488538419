import React, { useState,useContext,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import styles from './DeletePost.module.css';
import axios from "axios";
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import {useSelector,useDispatch} from 'react-redux';
import {closeDeletepost}  from '../features/deletepost';
import {UserContext} from "../App";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';

//import {closeModal} from '../features/counter/show';
function DeletePost(props) {
  //const [show, setShow] = useState(showModal);
  const show = useSelector((state)=>state.deletepost.value);
  const dispatch  =  useDispatch();
  const [inputs, setInputs] = useState([]);
  const user = useContext(UserContext);
  const handleClose = () => dispatch(closeDeletepost());
  const inputRef = useRef();
  const [image,setImage] = useState();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
 
  setInputs(values => ({...values, [name]: value}));
}
function insertUserID(){
  const name = "userID";
  const value = user;

  setInputs(values=>({...values,[name]:value}))
}

const deleteuserPost =  async (event,postID) => {
 
  let axiosConfig = {
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;'
    }
  };
  let  param = {
    param:{'postID':postID}
  }
  event.preventDefault();

  axios.post(`https://whoozhaf.000webhostapp.com/API/post.php/delete/${postID}`,param,axiosConfig).then(function(response){

      if(response.data['status']==0){
    
      console.log(response.data)
       
      }
      else{
        console.log(response.data)
          handleClose();
          navigate('/post');
       
      }
      
  }).catch(error => {
    console.log(error);
    // handle error
  });;
  
}
useEffect(() => {
  insertUserID();
}, []);
  return (
    <>
 

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{fontSize:16}}>
          <Modal.Title  style={{fontSize:24}}>Upload Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={event=>props.deleteuserPost(event)}>

        <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={(e) => {
              setFiles(e, 'a');
              setImage(URL.createObjectURL(e.target.files[0]));
              setFile(e.target.files[0]);
           
              inputRef.current.value = null;

            }}
          />
<Form.Group className="mb-3"  style={{textAlign:'center'}}>
<Form.Label style={{fontSize:'18px'}}>Are you sure you want  to delete this post?</Form.Label>

    <Button type="submit"  className={styles.btnloginForm +' '+styles.btnLogin} >
        Yes
    </Button>

</Form.Group>

<Form.Group className="mb-3" style={{textAlign:'center'}}>
   

<Button variant="light" type="button" className={styles.btnloginForm +' '+styles.btnCancel}  onClick={handleClose}>
          No
 </Button>


</Form.Group>
</Form>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeletePost;
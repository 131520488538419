import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form  } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiEye,HiMinusCircle,HiPlusCircle } from "react-icons/hi";
import { useEffect, useState,useMemo } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios"
import styles  from './UpdateProfile.module.css';
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import Modal from 'react-bootstrap/Modal';

import {closeModal} from '../features/showModal';
function UpdateProfile({token}) {
  const show = useSelector((state)=>state.showModal.value);
  const[inputs,setInputs] = useState([]);
  const [users, setUsers] = useState([]);
  const [firstname, setFirstname] = useState([]);
  const [lastname, setLastname] = useState([]);
  const [address, setAddress] = useState([]);
  const [work, setWork] = useState([]);
  //const [birthday, setBirthday] = useState([]);
  const [contact, setContact] = useState([]);
  const [isFocustxtfirstname,setFocustxtfirstname] = useState(false);
  const [isFocustxtlastname,setFocustxtlastname] = useState(false);
  const [isFocustxtaddress,setFocustxtaddress] = useState(false);
  const [isFocustxtbday,setFocustxtbday] = useState(false);
  const [isFocustxtcontact,setFocustxtcontact] = useState(false);
  const [isFocustxtwork,setFocustxtwork] = useState(false);
  const dispatch = useDispatch();
  const handleClose = () => dispatch(closeModal());
  const navigate = useNavigate();

  const getUsersmemo = useMemo(()=>{
    return getUsers(token);
  },[])
  useEffect(() => {
  
  
        }, 
    [getUsersmemo]);

    async function getUsers(token) {
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
  
        }
      };
      
      await  axios.get(`http://localhost/API/users.php/${token}/profile`,axiosConfig).then(function(response) {

            setUsers([response.data]);
            setFirstname(response.data[0].firstname);
            setLastname(response.data[0].lastname);
            setAddress(response.data[0].user_address);
            setWork(response.data[0].work);
            setContact(response.data[0].contact);
           
        });
    
    }
    const  updateProfile = async (event,userID)=>{
      
      event.preventDefault();
    
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json',
  
        }
      };

      await axios.post(`http://localhost/API/users.php/update/${userID}`,users[0][0],axiosConfig).then(function(response){
      console.log(response.data);
      }).catch(function(error){
        console.log(error);
      });
     
      cancelfirstname();
      cancelastname();
      canceladdress();
      cancelbday();
      cancelwork();
      cancelcontact();
      navigate('/profile');
    }
    const changeaddress =  (event)=>{

      const name = event.target.name;
      const value = event.target.value;
   
      const userNext = users;
      if(name=="firstname"){
        userNext[0][0].firstname = value;
      }
      if(name=="lastname"){
        userNext[0][0].lastname = value;
      }
      if(name=="address"){
        userNext[0][0].address = value;
      }
      if(name=="work"){
        userNext[0][0].work = value;
      }
     // if(name=="birthday"){
        //userNext[0][0].birthday = value;
      //}
      if(name=="contact"){
        userNext[0][0].contact = value;
      }
    
     setUsers(userNext);
   
     setFirstname(users[0].firstname);
     setLastname(users[0].lastname);
     setAddress(users[0].address);
     setWork(users[0].work);
    // setBirthday(users[0].birthday);
     setContact(users[0].contact);
   
    }
    function onFocustxtfirstname(){
      setFocustxtfirstname(true);
    }
    const cancelfirstname = async(event)=>{
  

      setFocustxtfirstname(false);
   
    }
   
    function onFocustxtaddress(){
      setFocustxtaddress(true);
    }
    const canceladdress = async(event)=>{
  

      setFocustxtaddress(false);
   
    }
    function onFocustxtlastname(){
      setFocustxtlastname(true);
    }
    const cancelastname = async(event)=>{
  

      setFocustxtlastname(false);
   
    }
    function onFocustxtbday(){
      setFocustxtbday(true);
    }
    const cancelbday = async(event)=>{

      setFocustxtbday(false);
   
    }
    function onFocustxtcontact(){
      setFocustxtcontact(true);
    }
    const cancelcontact = async(event)=>{

      setFocustxtcontact(false);
   
    }
    function onFocustxtwork(){
      setFocustxtwork(true);
    }
    const cancelwork = async(event)=>{

      setFocustxtwork(false);
   
    }
    const capitalizeFirstLowercaseRest = (str) => {
      return (
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      );
    };
  return (
    <>
    
    <Modal show={show} onHide={handleClose} dialogClassName={styles.modalsize}   >
        <Modal.Header closeButton style={{fontSize:16}}>
          <Modal.Title   className={styles.modalTitle}>Edit Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal}>
        <Form  onSubmit={event=>updateProfile(event,token)}>
        <Row className={styles.rowTextarea + " "+"justify-content-center"}>
         
        <Col lg = "11" className={styles.colComentTextarea}>
    
                <Form.Control type="text"  value={firstname} className={styles.Textarea} name = "firstname" placeholder="Firstname"   onFocus={onFocustxtfirstname} onChange={changeaddress} />  
                <HiOutlinePencil className={styles.iconEditaddress} />
    
        </Col>
         </Row>
         <Row  className={isFocustxtfirstname?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={cancelfirstname}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="2"  sm="2" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                        Save
                  </Button>
              </Col>
            
            </Row>
            </Form>  
            <Form  onSubmit={event=>updateProfile(event,token)}>
        <Row className={styles.rowTextarea + " "+"justify-content-center"}>
         
        <Col lg = "11" className={styles.colComentTextarea}>
    
                <Form.Control type="text"  value={lastname} className={styles.Textarea} name = "lastname" placeholder="Firstname"   onFocus={onFocustxtlastname} onChange={changeaddress} />  
                <HiOutlinePencil className={styles.iconEditaddress} />
    
            </Col>
         </Row>
         <Row  className={isFocustxtlastname?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={cancelastname}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="2"  sm="2" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                        Save
                  </Button>
              </Col>
            
            </Row>
            </Form>  
            <Form  onSubmit={event=>updateProfile(event,token)}>
        <Row className={styles.rowTextarea + " "+"justify-content-center"}>
         
        <Col lg = "11" className={styles.colComentTextarea}>
    
                <Form.Control type="text"  value={address} className={styles.Textarea} name = "address" placeholder="Address"   onFocus={onFocustxtaddress} onChange={changeaddress} />  
                <HiOutlinePencil className={styles.iconEditaddress} />
    
            </Col>
         </Row>
         <Row  className={isFocustxtaddress?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={canceladdress}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="2"  sm="2" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                        Save
                  </Button>
              </Col>
            
            </Row>
            </Form>  
        <Form  onSubmit={event=>updateProfile(event,token)}>
       
          <Row  className={isFocustxtbday?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={cancelbday}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="3"  sm="3" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                  Save
                  </Button>
              </Col>
            
            </Row>
            </Form>  
            <Form  onSubmit={event=>updateProfile(event,token)}>
          <Row className={styles.rowTextarea+ " "+"justify-content-center"} >
         
         <Col lg = "11" className={styles.colComentTextarea}>
       
                 <Form.Control type="text"   value={contact} className={styles.Textarea} name = "contact" placeholder="Contact"  onFocus={onFocustxtcontact} onChange={changeaddress}/>  
                 <HiOutlinePencil className={styles.iconEditaddress}/>
         
             </Col>
          </Row>
          <Row  className={isFocustxtcontact?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={cancelcontact}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="2"  sm="2" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                  Save
                  </Button>
              </Col>
            
            </Row>
            </Form> 
            <Form  onSubmit={event=>updateProfile(event,token)}>
      <Row className={styles.rowTextarea+ " "+"justify-content-center"} >
         
        <Col lg = "11" className={styles.colComentTextarea}>
        
                <Form.Control type="text"   value={work} className={styles.Textarea} name = "work" placeholder="Work"   onFocus={onFocustxtwork} onChange={changeaddress} />  
                <HiOutlinePencil className={styles.iconEditaddress}/>
           
            </Col>
         </Row>
         <Row  className={isFocustxtwork?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="7"sm="7" >
                
              </Col>
              <Col  lg={"2"}sm="2" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={cancelwork}>
                        Cancel
                  </Button>
              </Col>
              <Col lg="2"  sm="2" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                  Save
                  </Button>
              </Col>
            
            </Row>
         </Form> 
         </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
  

    </>
  );
}

export default UpdateProfile;
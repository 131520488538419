import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle,HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect,useState,useContext } from 'react';
import PostComments from './Comments'
import styles from './addfeedback.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import Counts from './counts';
function Addfeedback(props) {
  
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);
  const[contents,setContent]  = useState([]);
  const[choicecontents,setchoicecontent]  = useState([]);
  const[countcomments,setcountcomments]  = useState([0]);
  const[countpros,setcountpros] = useState([0]);
  const[countcons,setcountcons] = useState([0]);
  const[inputs,setInputs] = useState([]);
  const[feedback,setFeedback] = useState('');
  const[choice,setchoice] = useState('');

  const  navigate = useNavigate();
  const user = useContext(UserContext);

  const [isFocustxtcomment,setFocustxtcomment] = useState(false);



 

 
 
    
      useEffect(() => {
      
        
      }, []);
  

  
   
      function onFocustxtcomment(){
        setFocustxtcomment(true);
      }

        function onBlurtxtcomment(){
          setFocustxtcomment(false);
      }
     
  return (
    
  
          <Form  name="a" onSubmit = {event=>props.addfeedback(event)}>
          <Row style={{marginTop:30}} className={"justify-content-center"}>
            <Col lg = "11" className={styles.colComentTextarea}>
                <Form.Control as="textarea"  value={props.feedback} className={styles.comentTextarea} name = "feedback" onChange={props.handlechange}  placeholder="Leave a Feedback here" onFocus={onFocustxtcomment}  />  
            </Col>
            
          </Row>
        
              <Row  className={isFocustxtcomment?styles.rowbtnComment:styles.rowbtnInvicomment}>
              <Col  lg="6"sm="6" >
                
              </Col>
              <Col  lg={"3"}sm="3" className={styles.colbtnComment}>
                  <Button type="button" variant="light"  className={styles.btnCancel} onClick={props.cancel}>
                       Cancel
                  </Button>
              </Col>
              <Col lg="3"  sm="3" className={styles.colbtnComment} >
                  <Button type="submit" variant="primary" className={styles.btnComment} >
                        Submit
                  </Button>
              </Col>
            
            </Row>
          </Form>
             
              
          
  );
}

export default Addfeedback;
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle,HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect,useState,useContext, useMemo } from 'react';
import PostComments from './Comments';
import Addfeedback from  './addfeedback';
import styles from './Content.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import Counts from './counts';
function FeedbackContent({token}) {
  
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);
  const[contents,setContent]  = useState([]);
  const[choicecontents,setchoicecontent]  = useState([]);
  const[countcomments,setcountcomments]  = useState([0]);
  const[countpros,setcountpros] = useState([0]);
  const[countcons,setcountcons] = useState([0]);
  const[inputs,setInputs] = useState([]);
  const[comment,setComment] = useState('');
  const[choice,setchoice] = useState('');
  const  navigate = useNavigate();
  const user = useContext(UserContext);
  const[Reload,setReload] = useState(false);
  const [count,setCount] = useState(0);
  const [isFocustxtcomment,setFocustxtcomment] = useState(false);
  const[feedback,setFeedback] = useState('');
  const getConentmemo = useMemo(()=>{
    return getContent();
  },[])
  let axiosConfig = {
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',

    }
  };


  const cancel = async(event)=>{
    setInputs([]);
    setFeedback('');

    setFocustxtcomment(false);
 
  }
   
  function insertUserID(){
    const name = "userID";
    const value = token;

    setInputs(values=>({...values,[name]:value}))
  }
  function handleClick()
    {
      setproisActive(current=>!current);
      }
     
    
      useEffect(() => {
      
        insertUserID();
          
        
         // const onScroll = () => setOffset(window.pageYOffset);
          //if(offset>0){ 
           // setScroll(true); 
         
          //}
          //else{
           //  setScroll(false); 
         // }
          
          // clean up code
          //window.removeEventListener('scroll', onScroll);
         // window.addEventListener('scroll', onScroll, { passive: true });
         // return () => window.removeEventListener('scroll', onScroll);
      }, [Reload,getConentmemo]);
  
    
      function getContent(){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get("https://whoozhaf.000webhostapp.com/API/feedback.php",axiosConfig).then(function(response){
          setContent(response.data);
          
          
       
        });
        return contents;
      }
     
      const handlechange =  (event)=>{

        const name = event.target.name;
        const value = event.target.value;
        setFeedback(event.target.value);
        setInputs(values=>({...values,[name]:value}));
        
      }
   
      const addfeedback = async(event)=>{
        event.preventDefault();
      
       
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
      
       
    
        axios.post("https://whoozhaf.000webhostapp.com/API/feedback.php",inputs,axiosConfig).then(function(response){
          
      console.log(response.data);
      console.log(inputs);
        });
        
        getContent();
        setFeedback('');
        navigate('/feedback');
        setReload(!Reload);
      }
      function onFocustxtcomment(){
        setFocustxtcomment(true);
      }
        function onBlurtxtcomment(){
          setFocustxtcomment(false);
      }
  return (
    
    <Container fluid="md" className={styles.container} >

    
     
      <Row className={"justify-content-md-center"}>
        <Col md="6"  > 
        <Card className= {styles.card}  >
        <Card.Header className={styles.rowHeader} ></Card.Header>
      <Card.Body >
      <Container fluid="md" >
          
       
          <Addfeedback  token={token} addfeedback={addfeedback} handlechange={handlechange} userID={token} feedback={feedback} cancel={cancel}></Addfeedback>
   
             
           </Container>
        <Card.Title>
        {contents.map((content,key)=>
              {
                return(
        <Container fluid="md" key={key} >
      
          <Row className={styles.rowName}>
           <Col  xs="2" lg="2" className = {styles.colLogo}> 
              <Image rounded  src={userImage} className={styles.imgLogo} alternate="no image"/>
            </Col>
            <Col xs="6" lg="10" className = {styles.Colname}> 
                  <Stack gap={1}  >
                  <Card.Text className={styles.textName}>{content.firstname + " " +content.lastname}</Card.Text>
                  <Card.Text className={styles.cardTextTime}>{content.timeago} ago</Card.Text>
                  </Stack>
              
            </Col>
          
          </Row>
          <Row className={styles.rowContent}>
       
            <Col md="12" className={styles.colContent}> 
            <Card.Text className={styles.cardCotent}>
             {content.feedback}
            </Card.Text>
              
            </Col>
          </Row>
      
        </Container>
          );
        }
            
        )
        }
        </Card.Title>
       
    
     
      </Card.Body>
    </Card></Col>
      </Row>
        
    </Container>
  );
}

export default FeedbackContent;
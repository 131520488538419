import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form,Button,Div } from 'react-bootstrap';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { useContext, useEffect, useState,useMemo } from 'react';
import Replies from './Replies';
import styles from './Commentlike.module.css';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import {UserContext} from "../App";
import {addLike} from '../features/inputs';
import {useSelector,useDispatch} from 'react-redux';
function Commentlike(props) {
   const dispatch = useDispatch();
    const [offset, setOffset] = useState(0);
    const [scroll,setScroll] = useState(false);
    const[contents,setContent]  = useState([]);
    //const[inputs,setInputs] = useState([]);
    const[comment,setComment] = useState('');
    const  navigate = useNavigate();
    const [isReplies,setReplies] = useState([]);
    const [isFocustxtcomment,setFocustxtcomment] = useState(false);
    const [reload,setReload] = useState(false);
    const user = useContext(UserContext);
    const [count,setCount] = useState(0);
    const [isLike,setLike] = useState(false);
    const[likeCount,setlikeCount] = useState(0);

    //const inputs = useSelector((state)=>state.addLike.inputs);
    const inputs = {inputs: {
      commentID:0,
      like: "",
      userID:0
      }}
        

          function getLike(commentID){
            
            let axiosConfig = {
              headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        
              }
            };
            axios.get(`http://localhost/API/likes.php/${commentID}`,axiosConfig).then(function(response){

              //count = response.data['count'];
              setlikeCount(response.data['count']);
              if(response.data['count']>0){ 
                setLike(true);
              }
            });
            return likeCount;
            
            }
           function unLike(event,commentID,like){
            event.preventDefault();
            setLike(!isLike);
            //dispatch(addLike({commentID:commentID,like:like,userID:props.userID}));
            inputs.commentID = commentID;
            inputs.like = like;
            inputs.userID = props.userID;
            let axiosConfig = {
              headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        
              }
            };
           axios.post(`http://localhost/API/likes.php`, inputs,axiosConfig).then(function(response){
             
               
     
            
            });
            
            }
         
   
   
  return (
      
    <Stack gap={0} direction="horizontal" >
              {!isLike?
              <AiOutlineLike className={props.type==="comment"?styles.likebutton:styles.likebutton2} onClick={event=>unLike(event,props.commentID,"like")}/>
              :<AiFillLike className={props.type==="comment"?styles.likedbutton:styles.likedbutton2} onClick={event=>unLike(event,props.commentID,"unlike")}/>
              }
                  <label className={props.type==="comment"?styles.likecount:styles.likecount2}>{getLike(props.commentID)}</label>
</Stack>
           
  );
}

export default Commentlike;


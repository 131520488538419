import { createSlice, nanoid } from "@reduxjs/toolkit";


const initialState = {inputs: {
        commentID:0,
        like: "",
        userID:0
        }}
   

const inputsSlice = createSlice({
    name: 'inputs',
    initialState,
    reducers:{
        addLike(state,action){
            const {commentID,userID,like}  = action.payload
            state.inputs.commentID = 1
            state.inputs.userID = 1
            state.inputs.like = like
        },
     
    }
})


export const {addLike} = inputsSlice.actions

export default inputsSlice.reducer

import Navigationbar from './Navigationbar';
import Content from './Content';
import {Card,Row,Col,Container} from 'react-bootstrap';
import styles from './Homepage.module.css';
import Hottopic from './Hottopic';
import UploadModal from './UploadModal';

function Homepage({token}) {
  return (
    
<>
    
    <Card className={styles.cardPersonalinfo}>
 
  <Card.Body >
    <Card.Title>
    
    </Card.Title>
   
    <Container fluid="md" >
   
   
    
      <Row className={styles.rowcontent+ " "+"justify-content-center"} >
     
    <Col lg = "6" className={styles.colComentTextarea}>
    <Content token={token} />
          
        </Col>
    <Col lg = "1" className={styles.colComentTextarea}>
 

</Col>
<Col lg = "4" className={styles.colComentTextarea}>
    
   <Hottopic token={token}/>

</Col>
     </Row>
  

    </Container>
     
  
   

   
  </Card.Body>
</Card>


<UploadModal token={token} />
</>

  );
}

export default Homepage;
import { configureStore } from "@reduxjs/toolkit"
import counterReducer from "../features/counter/counterSlice";
import showReducer from "../features/counter/show";
import showModalSlice from '../features/showModal';
import uploadModalSlice from '../features/uploadModal';
import showDeletePost from '../features/deletepost';
import showpostSlice from '../features/showPost';
import postIDSlice from "../features/postID";
import inputsSlice from "../features/inputs";
export default configureStore({
    reducer: {
        counter: counterReducer,
        show: showReducer,
        showModal: showModalSlice,
        uploadModal: uploadModalSlice,
        deletepost: showDeletePost,
        showPost:showpostSlice,
        addPostID:postIDSlice,
        addLike:inputsSlice
    },
});
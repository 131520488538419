import React from 'react';
import  styles from './SideNav.module.css'; // Import the CSS file for styling
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import  Logo from '../images/logo.png';
import { MdSearch } from "react-icons/md";
import Stack from 'react-bootstrap/Stack';
import { BiHomeAlt } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { FaRegStar } from "react-icons/fa";
function SideNav ({token}){
  return (

    <div className={styles.sidenavbar}>
       <Stack direction="vertical" gap={1}>
       <Image  src={Logo} className={styles.logo} rounded/>
      <ul>
      
        <li>
       
          
          <a href="/">
          <Stack direction="horizontal" gap={4}>
          <BiHomeAlt className={styles.icon}/>
          <p>Home</p>
          </Stack>
          </a>
         
          </li>
        <li>    
        <a href="/profile">
          <Stack direction="horizontal" gap={4}>
          <FiUser className={styles.icon}/>
          <p>Profile</p>
          </Stack>
          </a>
          
          </li>
        <li>    
        <a href="/post">
          <Stack direction="horizontal" gap={4}>
          <FaRegStar className={styles.icon}/>
          <p>Trending</p>
          </Stack>
          </a>
          </li>
 
      </ul>
       </Stack>
      
    </div>
 
  );
};

export default SideNav;


import {createContext, React,useContext,useState,useEffect} from 'react';
//import Navigationbar from './components/Navigationbar';
import SideNav from './components/SideNav';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes , Route } from 'react-router-dom';
import './components/Content';
import Homepage from './components/Homepage';
import Login from './components/Login';
import LandingPage from './components/landingpage';
import Register from './components/Register';
import Profile from './components/Profile';
import Content from './components/Content';
import useToken from './components/token';
import Post from './components/Post';
import Counter from './features/counter/counter';
import PostComments from './components/Comments';
import {useNavigate,useParaams} from 'react-router-dom';
import Createdebate  from './components/Createdebate';
import Feedback from './components/Feedback';
import { Container, Row, Col } from 'react-bootstrap';
import useSize from "./components/useSize";
export const UserContext = createContext();
function App() {
  const { token, setToken } = useToken();
  const [user, setUsers] = useState(token);
  const windowsize = useSize();

  function setUservalue(){
    setUsers(token);
  }
  useEffect(()=>{
    setUservalue();
    token?document.body.style.backgroundColor = '#0e5c50':document.body.style.backgroundColor = '#fff';
  
  },[token])

    return (
      <>
      <UserContext.Provider value={token}>
        <Router>
        <Container fluid>
      <Row >
        <Col style={{height:windowsize[1]*.95}} md="auto">
     
        {token?<SideNav token ={token}/>:""}
        </Col>
        
        <Col style={{height:windowsize[1]*.95}} md="10">

        <Routes>
            <Route path='/'  exact element={!token?<LandingPage setToken={setToken} />:<Homepage token={user}/>} />
       
            <Route path='/services' />
      
            <Route path='/register' element={<Register/>} />
            <Route path='/profile' element={!token?<LandingPage setToken={setToken} />:<Profile token={user}/>}/>
            <Route path='/post' element={!token?<LandingPage setToken={setToken} />:<Post token={user}/>}/>
            <Route path='/create' element={!token?<LandingPage setToken={setToken} />:<Createdebate token={user}/>}/>
            <Route path='/feedback' element={!token?<LandingPage setToken={setToken} />:<Feedback token={user}/>}/>
          
          </Routes >
        </Col>
      </Row>
    </Container>
         
          
         
        </Router>
        </UserContext.Provider >
      </>
    );
  }
 


export default App;

import {createSlice} from '@reduxjs/toolkit';

export const showModalSlice = createSlice({
    name:'show',
    initialState:{
        value:false
    },
    reducers:{
        showModal:(state)=>{
            state.value=true
        },
        closeModal:(state)=>{
            state.value = false
        }
    }
})
export const {showModal,closeModal} = showModalSlice.actions;
export default showModalSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const uploadModalSlice = createSlice({
    name: 'show',
    initialState: {
        value: false
    },
    reducers: {
        openUpload: (state) => {
            state.value = true
        },
        closeUpload: (state) => {
            state.value = false
        }
    }
})
export const { openUpload, closeUpload } = uploadModalSlice.actions;
export default uploadModalSlice.reducer;
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle,HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect,useState,useContext, useMemo } from 'react';
import PostComments from './Comments';
import Addcomment from  './addcomment';
import styles from './Content.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import Counts from './counts';
function Content({token}) {
  
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);
  const[contents,setContent]  = useState([]);
  const[choicecontents,setchoicecontent]  = useState([]);
  const[countcomments,setcountcomments]  = useState([0]);
  const[countpros,setcountpros] = useState([0]);
  const[countcons,setcountcons] = useState([0]);
  const[inputs,setInputs] = useState([]);
  const[comment,setComment] = useState('');
  const[choice,setchoice] = useState('');
  const  navigate = useNavigate();
  const user = useContext(UserContext);
  const[Reload,setReload] = useState(false);
  const [count,setCount] = useState(0);
  const [isFocustxtcomment,setFocustxtcomment] = useState(false);

  const getConentmemo = useMemo(()=>{
    return getContent();
  },[Reload])
  let axiosConfig = {
    headers: {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',

    }
  };

  const handlechange =  (event)=>{

    const name = event.target.name;
    const value = event.target.value;
    setComment(event.target.value);
    setInputs(values=>({...values,[name]:value}));
    
  }
  function insertUserID(){
    const name = "userID";
    const value = token.token;

    setInputs(values=>({...values,[name]:value}))
  }
  function insertType(){
    const name = "choice";
    const value = "";

    setInputs(values=>({...values,[name]:value}))
  }
 
  function handleClick()
    {
      setproisActive(current=>!current);
      }
     
     
      useEffect(() => {
        
        insertUserID();
        insertType();
        getChoiceContent(token);
    
       // const onScroll = () => setOffset(window.pageYOffset);
        //if(offset>0){ 
         // setScroll(true); 
       
        //}
        //else{
         //  setScroll(false); 
       // }
        
        // clean up code
        //window.removeEventListener('scroll', onScroll);
       // window.addEventListener('scroll', onScroll, { passive: true });
       // return () => window.removeEventListener('scroll', onScroll);
    }, [Reload]);
  
    function getChoiceContent(userID){
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
  
        }
      };
      
      axios.get(`http://localhost/API/choice.php/${userID}`,axiosConfig).then(function(response){
        setchoicecontent(response.data);
   
        console.log(response.data)
      });
      
    }
      function getContent(){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get("http://localhost/API/post.php",axiosConfig).then(function(response){
          setContent(response.data);
          
          
       
        });
        return contents;
      }
      function getCountPros(postID){
   
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/pros`,axiosConfig).then(function(response){
        
          setcountpros(response.data['count'])
       
        });
        return countpros;
      }
      function getCountCons(postID){
   
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/cons`,axiosConfig).then(function(response){
         
          setcountcons(response.data['count'])
       
        });
        return countcons;
      }
      function getCountComments(postID){
     
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/comments`,axiosConfig).then(function(response){
          console.log(postID);
          setcountcomments(response.data['count'])
       
        });
        return countcomments;
      }
      const cancel = async(event)=>{
        setInputs([]);
        setComment('');
        getContent();
        insertUserID();
  
        setFocustxtcomment(false);
     
      }
      const choicepros = async(event,postID)=>{
        event.preventDefault();
        const choice = "Pros";
       
  
        axios.post(`http://localhost/API/choice.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
        console.log(inputs);
     
        });
        getChoiceContent(token.token);
        getContent();
        //navigate('/');
        setReload(!Reload);
      }
      const choicecons = async(event,postID)=>{
        event.preventDefault();

        const choice = "Cons";
     
      
        axios.post(`http://localhost/API/choice.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
         
       
        });
        getChoiceContent(token.token);
        getContent();
        //navigate('/');
        setReload(!Reload);
    }
    const addComment = async(event,postID,choice)=>{
      event.preventDefault();
    
     
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
  
        }
      };
    
     
  
      axios.post(`http://localhost/API/comments.php/${postID}/${choice}`,inputs,axiosConfig).then(function(response){
      
        
      });
      getContent();
      getChoiceContent(token);
      setComment('');
      //navigate('/');
      setReload(!Reload);
    }
      function onFocustxtcomment(){
        setFocustxtcomment(true);
      }
        function onBlurtxtcomment(){
          setFocustxtcomment(false);
      }
  return (
    
    <Container className={styles.container} fluid="md">
  
  {contents.map((content,key)=>
              {
                return(
      <Row key={key} className={"justify-content-md-center"}>
        <Col md="12"  className= {styles.cardColumn} > 
       
        <Container fluid="md" className={styles.container} >
      
          <Row className={styles.rowName}>
           <Col  xs="2" lg="2" className = {styles.colLogo}> 
              <Image rounded  src={userImage} className={styles.imgLogo} alternate="no image"/>
            </Col>
            <Col xs="8" lg="8" className = {styles.Colname}> 
                  <Stack gap={1}  >
                  <Card.Text className={styles.textName}>{content.firstname + " " +content.lastname}</Card.Text>
                  <Card.Text className={styles.cardTextTime}>{content.timeago} ago</Card.Text>
                  </Stack>
              
            </Col>
        
          
          </Row>
          <Row className={styles.rowContent}>
       
            <Col md="12" className={styles.colContent}> 
            <Card.Text className={styles.cardCotent}>
             {content.content}
            </Card.Text>
              
            </Col>
          </Row>
      
        </Container>
     
       
    
        <Container fluid="md" >
          {
            choicecontents.length==0? <Row className={"justify-content-center"+" "+styles.rowChoices}>
            <Col  md={"5"} className={styles.colChoicespros}> 
 
            <Button type="submit" variant="light"  className={styles.btnChoicespros} onClick={event=>choicepros(event,content.postID)}>
                        <HiOutlinePlusCircle  className={styles.iconChoicespros}/>{content.pros} 
                   </Button>
         
             </Col>
 
             <Col   md={"5"} className={styles.colChoicescons}> 
             <Button type="submit" variant="light"  className={styles.btnChoicescons} onClick={event=>choicecons(event,content.postID)}>
          
             <HiOutlineMinusCircle className={styles.iconChoicescons}/>    {content.cons}   
              </Button>
           
             </Col>
           </Row>
           :choicecontents.filter(choicecontent=>choicecontent.postID==content.postID).length>0?
           choicecontents.filter(choicecontent=>choicecontent.postID==content.postID).map((choicecontent,key)=>
            {
              return(
                
      <Row key={key} className={"justify-content-center"+" "+styles.rowChoices}>
         <Col  md={"5"} className={styles.colChoicespros}> 

         <Button type="submit" variant="light" disabled className={choicecontent.choice=="Pros"?styles.btnChoicespros2:styles.btnChoicespros} onClick={event=>choicepros(event,content.postID)}>
                     <HiOutlinePlusCircle  className={styles.iconChoicespros}/>  {content.pros} 
                </Button>
      
          </Col>

          <Col   md={"5"} className={styles.colChoicescons}> 
          <Button type="submit" variant="light" disabled className={choicecontent.choice=="Pros"?styles.btnChoicescons:styles.btnChoicescons2} onClick={event=>choicecons(event,content.postID)}>
        
          <HiOutlineMinusCircle className={styles.iconChoicescons}/>   {content.cons}   
           </Button>
        
          </Col>
        </Row>
       
         )
        }
          )
          :<Row key={key}  className={"justify-content-center"+" "+styles.rowChoices}>
          <Col  md={"5"} className={styles.colChoicespros}> 
  
          <Button type="submit" variant="light"  className={styles.btnChoicespros} onClick={event=>choicepros(event,content.postID)}>
                      <HiOutlinePlusCircle  className={styles.iconChoicespros}/>  {content.pros} 
                 </Button>
       
           </Col>
  
           <Col   md={"5"} className={styles.colChoicescons}> 
           <Button type="submit" variant="light"  className={styles.btnChoicescons} onClick={event=>choicecons(event,content.postID)}>
           {content.cons}   
           <HiOutlineMinusCircle className={styles.iconChoicescons}/> 
            </Button>
         
           </Col>
         </Row>

          }
       
      
       <Counts postID  = {content.postID} style = {"modal"} userID = {token}/>
             
       <Addcomment postID = {content.postID}  token={token} Reload={Reload} addComment={addComment} handlechange={handlechange} comment = {comment} cancel = {cancel} isFocustxtcomment ={isFocustxtcomment} onFocustxtcomment={onFocustxtcomment}></Addcomment>
          
          {/* Comments */}
       
        
          <Row className={styles.comment}>
            {/* Pros comments */}
            <Col  md={"12"} > 
            <PostComments prosisActivve={prosisActivve} reload = {Reload} onClick ={handleClick} Types={"Pros"} postID={content.postID} userID = {token} link ={"content"}/>
             {/* Pros comments */}
            </Col>
          </Row>
         
        </Container>
      </Col>
      </Row>
          );
        }
            
        )
        }
     
    </Container>
  );
}

export default Content;
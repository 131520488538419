import React, { useState,useContext,useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import styles from './UploadModal.module.css';
import axios from "axios";
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import {useSelector,useDispatch} from 'react-redux';
import {closeUpload} from '../features/uploadModal';
import {UserContext} from "../App";
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';
//import {closeModal} from '../features/counter/show';
function UploadModal({token}) {
  //const [show, setShow] = useState(showModal);
  const show = useSelector((state)=>state.uploadModal.value);
  const dispatch  =  useDispatch();
  const [inputs, setInputs] = useState([]);
  const user = useContext(UserContext);
  const handleClose = () => dispatch(closeUpload());
  const inputRef = useRef();
  const [image,setImage] = useState();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();
  const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
 
  setInputs(values => ({...values, [name]: value}));
}
function insertUserID(){
  const name = "userID";
  const value = user;

  setInputs(values=>({...values,[name]:value}))
}

  const handleSubmit =  async (event) => {
    const formData = new FormData();
    formData.append("image", file);
    let axiosConfig = {
      headers: {
          'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',

      }
    };
    event.preventDefault();
 
    axios.post(`http://localhost/API/users.php/upload/${token}`, formData,axiosConfig).then(function(response){

        if(response.data['status']==0){
       console.log(response.data);
         
        }
        else{
          handleClose();
            navigate('/profile');
         
        }
        
    });
    
}
useEffect(() => {
  insertUserID();
}, []);
  return (
    <>
 

      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton style={{fontSize:16}}>
          <Modal.Title   className={styles.modalTitle}>Upload Picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.modal}>
        <Form onSubmit={handleSubmit}>

        <input
            ref={inputRef}
            type="file"
            multiple
            style={{ display: 'none' }}
            onChange={(e) => {
              setFiles(e, 'a');
              setImage(URL.createObjectURL(e.target.files[0]));
              setFile(e.target.files[0]);
           
              inputRef.current.value = null;

            }}
          />
<Form.Group className="mb-3"  style={{textAlign:'center'}}>
<Image rounded  src={image}  className={styles.imgProfile} />
<Button type="button"  className={styles.btnupload} onClick={() => inputRef.current.click()}>
        Upload
    </Button>
    <Button type="submit"  className={styles.btnloginsave +' '+styles.btnLogin} >
        Save
    </Button>

</Form.Group>


</Form>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadModal;
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form  } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiEye,HiMinusCircle,HiPlusCircle } from "react-icons/hi";
import { useEffect, useState,useMemo } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios"
import styles  from './ProfileInfo.module.css';
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';

import {openUpload} from '../features/uploadModal';
import {showModal} from '../features/showModal';
import UploadModal from './UploadModal';
import UpdateProfile from './UpdateProfile';
function ProfileInfo({token}) {

  const[inputs,setInputs] = useState([]);
  const [users, setUsers] = useState([]);
  const [firstname, setFirstname] = useState([]);
  const [lastname, setLastname] = useState([]);
  const [address, setAddress] = useState([]);
  const [work, setWork] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [contact, setContact] = useState([]);
  const [isFocustxtfirstname,setFocustxtfirstname] = useState(false);
  const [isFocustxtlastname,setFocustxtlastname] = useState(false);
  const [isFocustxtaddress,setFocustxtaddress] = useState(false);
  const [isFocustxtbday,setFocustxtbday] = useState(false);
  const [isFocustxtcontact,setFocustxtcontact] = useState(false);
  const [isFocustxtwork,setFocustxtwork] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  const getUsersmemo = useMemo(()=>{
    return getUsers(token);
  },[])
  useEffect(() => {

    console.log(token);
 
        }, 
    [getUsersmemo]);
    const handleShow = () => {
      dispatch(openUpload());
    
    }
    const handleshowModal = () => dispatch(showModal());
    async function getUsers(token) {
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
  
        }
      };
      
      await  axios.get(`http://localhost/API/users.php/${token}/profile`,axiosConfig).then(function(response) {
           console.log(token);
            setUsers(response.data);
            setFirstname(response.data[0].firstname);
            setLastname(response.data[0].lastname);
            setAddress(response.data[0].user_address);
            setWork(response.data[0].work);
            setBirthday(response.data[0].birthday);
            setContact(response.data[0].contact);
           
        });
    
    }
    const  updateProfile = async (event,userID)=>{
      
      event.preventDefault();
    
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json',
  
        }
      };

      await axios.post(`https://whoozhaf.000webhostapp.com/API/users.php/update/${userID}`,users[0][0],axiosConfig).then(function(response){
      
       
      console.log(response.data)
      }).catch(function(error){
        console.log(error);
      });
      cancelfirstname();
      cancelastname();
      canceladdress();
      cancelbday();
      cancelwork();
      cancelcontact();
      navigate('/profile');
    }
    const changeaddress =  (event)=>{

      const name = event.target.name;
      const value = event.target.value;
   
      const userNext = users;
      if(name=="firstname"){
        userNext[0][0].firstname = value;
      }
      if(name=="lastname"){
        userNext[0][0].lastname = value;
      }
      if(name=="address"){
        userNext[0][0].address = value;
      }
      if(name=="work"){
        userNext[0][0].work = value;
      }
      if(name=="birthday"){
        userNext[0][0].birthday = value;
      }
      if(name=="contact"){
        userNext[0][0].contact = value;
      }
    
     setUsers(userNext);
     console.log(userNext[0][0]);
     setFirstname(users[0].firstname);
     setLastname(users[0].lastname);
     setAddress(users[0].address);
     setWork(users[0].work);
     setBirthday(users[0].birthday);
     setContact(users[0].contact);
  
    }
    function onFocustxtfirstname(){
      setFocustxtfirstname(true);
    }
    const cancelfirstname = async(event)=>{
  

      setFocustxtfirstname(false);
   
    }
   
    function onFocustxtaddress(){
      setFocustxtaddress(true);
    }
    const canceladdress = async(event)=>{
  

      setFocustxtaddress(false);
   
    }
    function onFocustxtlastname(){
      setFocustxtlastname(true);
    }
    const cancelastname = async(event)=>{
  

      setFocustxtlastname(false);
   
    }
    function onFocustxtbday(){
      setFocustxtbday(true);
    }
    const cancelbday = async(event)=>{

      setFocustxtbday(false);
   
    }
    function onFocustxtcontact(){
      setFocustxtcontact(true);
    }
    const cancelcontact = async(event)=>{

      setFocustxtcontact(false);
   
    }
    function onFocustxtwork(){
      setFocustxtwork(true);
    }
    const cancelwork = async(event)=>{

      setFocustxtwork(false);
   
    }
    const capitalizeFirstLowercaseRest = (str) => {
      return (
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      );
    };
  return (
    <>
    {users.map((user, key) =>
      {
      return(
        <div key={key}>
    
      <label className={styles.labeltitle}>Profile</label>
        <Card className={styles.cardPersonalinfo}>
     
      <Card.Body className={styles.cardbody} >
      
   
       
        <Container fluid="md" className={styles.container}>
       
        <Row className={styles.rowprofile}>
         
        <Col lg = "12" className={styles.profilebg}>
    
                
    
            </Col>
         </Row>
         <Row className={styles.rowprofile}>
         
         <Col className={styles.colprofile}>
     
         <Image   src={user.image===null?userImage:"http://localhost/image/"+ user.image}  className={styles.profileimage}  />
         <div className={styles.containerIcon}><HiOutlinePencil className={styles.iconCreate} onClick={handleShow}/></div>
     
             </Col>
          </Row>
          <Row className={styles.rowname}>
         
         <Col lg = "6" className={styles.profilename}>
     
                 <label className={styles.labelname} style={{ textTransform: 'capitalize' }}>{firstname+ " " +lastname}</label>
     
             </Col>
             <Col lg = "4" className={styles.profileedit}>
     
             <Button type="button"  className={styles.btneditprofile} onClick={handleshowModal}> 
             <HiOutlinePencil className={styles.iconeditprofile}/>Edit Profile
                        </Button>

           </Col>
          </Row>
          <Row className={styles.rowname}>
         
         <Col lg = "12" className={styles.profileemail}>
     
                 <label className={styles.labelemail}>Jhersy@gmail.com</label>
     
             </Col>
          </Row>
          <Row className={styles.rowaddress}>
         
         <Col lg = "12" className={styles.profileaddress}>
     
                 <label className={styles.labeladdress}>Address: {address}</label>
     
             </Col>
          </Row>
          <Row className={styles.rowwork}>
         
         <Col lg = "12" className={styles.profilecontactwork}>
     
                 <label className={styles.labelcontactwork}>Contact: {contact}</label>
     
             </Col>
          </Row>
          <Row className={styles.rowcontact}>
         
         <Col lg = "12" className={styles.profilecontactwork}>
     
                 <label className={styles.labelcontactwork}>Work: {work}</label>
     
             </Col>
          </Row>
        </Container>
         
    
       
    
       
      </Card.Body>
    </Card>
    <UpdateProfile token={token} />
  
    <UploadModal token={token} />
    </div>
      )}
    )}
     </>
  );
}

export default ProfileInfo;
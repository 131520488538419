import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle,HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect,useState,useContext,useRef } from 'react';
import PostComments from './Comments'
import styles from './addcomment.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../App";
import Counts from './counts';
import { useLayoutEffect } from 'react';
import useAutoSizeTextArea from "./autoResizeTextarea";
function Addcomment(props) {
  
  const [prosisActivve,setproisActive] = useState(true);
  const [offset, setOffset] = useState(0);
  const [scroll,setScroll] = useState(false);
  const[contents,setContent]  = useState([]);
  const[choicecontents,setchoicecontent]  = useState([]);
  const[countcomments,setcountcomments]  = useState([0]);
  const[countpros,setcountpros] = useState([0]);
  const[countcons,setcountcons] = useState([0]);
  const[inputs,setInputs] = useState([]);

  const[choice,setchoice] = useState('');
  const [reload,setReload] = useState(false);
  const  navigate = useNavigate();
  const user = useContext(UserContext);

  const [isFocustxtcomment,setFocustxtcomment] = useState(false);

  useAutoSizeTextArea("title-input", props.comment);

  function insertUserID(){
    const name = "userID";
    const value = props.token;

    setInputs(values=>({...values,[name]:value}))
  }
  function insertType(){
    const name = "choice";
    const value = "";

    setInputs(values=>({...values,[name]:value}))
  }

      useEffect(() => {
       
          insertUserID();
          insertType();
          getChoiceContent(props.token);
 
        
          setReload(props.Reload);
      }, []);
    
      function getChoiceContent(userID){
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        
        axios.get(`http://localhost/API/choice.php/${userID}`,axiosConfig).then(function(response){
          setchoicecontent(response.data);
         
       
        });
        
      }
  
   
     
      function onFocustxtcomment(){
        setFocustxtcomment(true);
      }

        function onBlurtxtcomment(){
          setFocustxtcomment(false);
      }
      const cancel = (event)=>{
        event.preventDefault()
        setInputs([]);
        insertUserID();
  
        setFocustxtcomment(false);
     
      }
  return (
    
  
          choicecontents.map((choicecontent,key)=>
              {
                return(
          <Form  key={key}  name="a" onSubmit={event=>props.addComment(event,props.postID,choicecontent.choice,inputs)} >
          <Row style={{marginTop:30}} className={"justify-content-center"}>
            <Col lg = "11" className={styles.colComentTextarea}>
               
                <Stack gap={3}  direction ={"horizontal"}>
                <Image rounded  src={userImage} className={styles.commentimg}/>
                <Form.Control as="textarea"  value={props.comment} className={styles.comentTextarea} name = "comment" onChange={props.handlechange}  placeholder="Leave a comment here" onFocus={onFocustxtcomment}  id="title-input"/>  
                <Button type="submit"  className={styles.btnComment} >
                        Comment
                  </Button>
                  </Stack>
              
            </Col>
            
          </Row>
        
          </Form>
                )
              }
                )
              
          
  );
}

export default Addcomment;
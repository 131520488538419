import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card, Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";

import { BiMessageRoundedDetail } from "react-icons/bi";
import { useEffect, useState, useContext } from 'react';
import PostComments from './Comments'
import styles from './counts.module.css';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import { FiPlusCircle } from "react-icons/fi";
import { FiMinusCircle } from "react-icons/fi";
import { FaRegBookmark } from "react-icons/fa";
import { BiMessageAltDetail } from "react-icons/bi";
import { FaBookmark } from "react-icons/fa";
import SaveButton from './saveButton';
function Counts(props) {

    const [prosisActivve, setproisActive] = useState(true);
    const [offset, setOffset] = useState(0);
    const [scroll, setScroll] = useState(false);
    const [contents, setContent] = useState([]);
    const [choicecontents, setchoicecontent] = useState([]);
    const [countcomments, setcountcomments] = useState([0]);
    const [countpros, setcountpros] = useState([0]);
    const [countcons, setcountcons] = useState([0]);
    const [inputs, setInputs] = useState([]);
    const [comment, setComment] = useState('');
    const [choice, setchoice] = useState('');
    const navigate = useNavigate();
    const user = useContext(UserContext);
    const [isFocustxtcomment, setFocustxtcomment] = useState(false);
    const [isSave,setSave] = useState(true);


    function handleClick() {
        setproisActive(current => !current);
    }


    useEffect(() => {


        getCountPros(props.postID);
        getCountCons(props.postID);
        getCountComments(props.postID);
      
    }, [props.postID]);



    function getCountPros(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/pros`, axiosConfig).then(function(response) {

            setcountpros(response.data['count'])
           
        });
        

    }

    function getCountCons(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/cons`, axiosConfig).then(function(response) {

            setcountcons(response.data['count'])

        });

    }

    function getCountComments(postID) {

        let axiosConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

            }
        };
        axios.get(`http://localhost/API/counts.php/${postID}/comments`, axiosConfig).then(function(response) {

            setcountcomments(response.data['count'])

        });

    }


    return (




        <Row className={styles.rowreact}>
         
        <Col  className={props.style=="hottopic"?styles.colreact1:styles.colreact2}>
       
               <Stack gap={1} direction="horizontal" >
               <BiMessageAltDetail  className={styles.icon}/>
               <label className={styles.count}>{countcomments}</label>
               <FiPlusCircle className={styles.icon} />
               <label className={styles.count}>{countpros}</label>
               <FiMinusCircle className={styles.icon}/>
               <label className={styles.count}>{countcons}</label>
               <SaveButton postID = {props.postID} userID = {props.userID}/>
               
               </Stack>
    
            </Col>
         </Row>

    );
}

export default Counts;
import {createSlice} from '@reduxjs/toolkit';

export const showpostSlice = createSlice({
    name:'show',
    initialState:{
        value:false
    },
    reducers:{
        showPost:(state)=>{
            state.value=true
        },
        closePost:(state)=>{
            state.value = false
        }
    }
})
export const {showPost,closePost} = showpostSlice.actions;
export default showpostSlice.reducer;


import {createSlice} from '@reduxjs/toolkit';
export const counterSlice = createSlice({

    name:'counter',
    initialState:{
        value:"true",
    },
    reducers:{
        increment:(state)=>{
            state.value="false"
        },
        decrement:(state)=>{
            state.value ="true"
        },
        incrementByAmount:(state,action)=>{
            state.value = action.type
        }
    }
})

export const {increment , decrement , incrementByAmount} = counterSlice.actions
export default  counterSlice.reducer
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form  } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { HiEye,HiMinusCircle,HiPlusCircle } from "react-icons/hi";
import { useEffect, useState,useMemo } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import axios from "axios"
import styles  from './Hottopic.module.css';
import { HiOutlinePencil } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import Counts from './counts';
import {showPost} from '../features/showPost';
import ModalPost from './ModalPost';
import UploadModal from './UploadModal';
import {addpostID} from '../features/postID';
function Hottopic({token}) {
  const[inputs,setInputs] = useState([]);
  const [users, setUsers] = useState([]);
  const [firstname, setFirstname] = useState([]);
  const [lastname, setLastname] = useState([]);
  const [address, setAddress] = useState([]);
  const [work, setWork] = useState([]);
  const [birthday, setBirthday] = useState([]);
  const [contact, setContact] = useState([]);
  const [isFocustxtfirstname,setFocustxtfirstname] = useState(false);
  const [isFocustxtlastname,setFocustxtlastname] = useState(false);
  const [isFocustxtaddress,setFocustxtaddress] = useState(false);
  const [isFocustxtbday,setFocustxtbday] = useState(false);
  const [isFocustxtcontact,setFocustxtcontact] = useState(false);
  const [isFocustxtwork,setFocustxtwork] = useState(false);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const show = useSelector((state)=>state.showPost.value);
  const[contents,setContent]  = useState([]);

  const getUsersmemo = useMemo(()=>{
    return getUsers(token);
  },[])
  useEffect(() => {

    console.log(token)
    getContent();
        }, 
    []);
    const handleShow = () => {dispatch(showPost());}
    //const handlePostID = (postID) => {dispatch(addpostID({pID:postID}))}
    const selectTopic = (event,postID)=>{
      event.preventDefault();
      dispatch(addpostID({pID:postID}));
      handleShow();
     
    }
   
    const sample = ()=>{
  
    }
    async function getUsers(token) {
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
  
        }
      };
      
      await  axios.get(`http://localhost/API/users.php/17/profile`,axiosConfig).then(function(response) {
       // console.log(response.data[0].firstname);
            setUsers([response.data]);
            setFirstname(response.data[0].firstname);
            setLastname(response.data[0].lastname);
            setAddress(response.data[0].address);
            setWork(response.data[0].work);
            setBirthday(response.data[0].birthday);
            setContact(response.data[0].contact);
           
        });
    
    }
    const  getContent = async ()=>{
      
  
    
      let axiosConfig = {
        headers: {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8;application/json',
  
        }
      };

      await axios.get(`http://localhost/API/hottopics.php/allpost`,axiosConfig).then(function(response){
  
      setContent(response.data);
      }).catch(function(error){
        console.log(error);
      });
  
    }
    const changeaddress =  (event)=>{

      const name = event.target.name;
      const value = event.target.value;
   
      const userNext = users;
      if(name=="firstname"){
        userNext[0][0].firstname = value;
      }
      if(name=="lastname"){
        userNext[0][0].lastname = value;
      }
      if(name=="address"){
        userNext[0][0].address = value;
      }
      if(name=="work"){
        userNext[0][0].work = value;
      }
      if(name=="birthday"){
        userNext[0][0].birthday = value;
      }
      if(name=="contact"){
        userNext[0][0].contact = value;
      }
    
     setUsers(userNext);

     setFirstname(users[0].firstname);
     setLastname(users[0].lastname);
     setAddress(users[0].address);
     setWork(users[0].work);
     setBirthday(users[0].birthday);
     setContact(users[0].contact);
  
    }
    function onFocustxtfirstname(){
      setFocustxtfirstname(true);
    }
    const cancelfirstname = async(event)=>{
  

      setFocustxtfirstname(false);
   
    }
   
    function onFocustxtaddress(){
      setFocustxtaddress(true);
    }
    const canceladdress = async(event)=>{
  

      setFocustxtaddress(false);
   
    }
    function onFocustxtlastname(){
      setFocustxtlastname(true);
    }
    const cancelastname = async(event)=>{
  

      setFocustxtlastname(false);
   
    }
    function onFocustxtbday(){
      setFocustxtbday(true);
    }
    const cancelbday = async(event)=>{

      setFocustxtbday(false);
   
    }
    function onFocustxtcontact(){
      setFocustxtcontact(true);
    }
    const cancelcontact = async(event)=>{

      setFocustxtcontact(false);
   
    }
    function onFocustxtwork(){
      setFocustxtwork(true);
    }
    const cancelwork = async(event)=>{

      setFocustxtwork(false);
   
    }
    const capitalizeFirstLowercaseRest = (str) => {
      return (
        str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      );
    };
  return (
    <>
     <label className={styles.labeltitle}>New</label>
        <Card className={styles.hottopiccard}>
     
      <Card.Body className={styles.cardbody}>
      
   
       
        <Container fluid="md" className={styles.container}>
      
        {contents.map((content,key)=>
              {
                return(
         <div key={key} className={styles.hottopicrow} onClick={event=>selectTopic(event,content.postID)}>         
         <Row  >
         
         <Col  className={styles.hottopiccol}>
        
         <Stack gap={3} direction="horizontal">
         <Image rounded  src={userImage} className={styles.imgLogo} alternate="no image"/>
          
          <Stack gap={0}>
        
          <label className={styles.labelname} style={{ textTransform: 'capitalize' }}>{firstname+ " " +lastname}</label>
          <label className={styles.labeltime}>{content.timeago}</label>
        </Stack>
        </Stack>
     
             </Col>
          </Row>
          <Row className={styles.rowcontent}>
         
         <Col  className={styles.colcontent}>
        
         
        
          <label className={styles.labelcontent}>  {content.content}</label>
    
     
             </Col>
          </Row>
          
          <Counts postID  = {content.postID} style = {"hottopic"} userID = {token}/>
          <Row className={styles.rowbordeline}>
          <Col  className={styles.colbordeline}>
          <div className={styles.bordeline}></div>
          </Col>

          </Row>
          </div>
                )
              }
            )
            }

          
        </Container>
         
    
       
    
       
      </Card.Body>
    </Card>
  
    <ModalPost token = {token}/>
 
    </>
  );
}

export default Hottopic;
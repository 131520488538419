import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import { Card,Form,Button,Div } from 'react-bootstrap';
import userImage from '../images/users.png';
import Image from 'react-bootstrap/Image';
import { useContext, useEffect, useState,useMemo } from 'react';
import Replies from './Replies';
import styles from './Comments.module.css';
import { useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import { AiOutlineLike } from "react-icons/ai";
import { AiFillLike } from "react-icons/ai";
import {UserContext} from "../App";
import {addLike} from '../features/inputs';
import {useSelector,useDispatch} from 'react-redux';
import { RiArrowDownSLine,RiArrowUpSLine  } from "react-icons/ri";
import Commentlike from './Commentlike';
import RepliesCount from './RepliesCount'
function Comments(props) {
   const dispatch = useDispatch();
   const postID = useSelector((state)=>state.addPostID.value);
    const [offset, setOffset] = useState(0);
    const [scroll,setScroll] = useState(false);
    const[contents,setContent]  = useState([]);
    //const[inputs,setInputs] = useState([]);
    const[comment,setComment] = useState('');
    const  navigate = useNavigate();
    const [isReplies,setReplies] = useState([]);
    const [isFocustxtcomment,setFocustxtcomment] = useState(false);
    const [reload,setReload] = useState(false);
    const user = useContext(UserContext);
    const [count,setCount] = useState(0);
    const [isLike,setLike] = useState(false);
    const link = props.link;
    const pid = link=="content"?props.postID:postID;
    //const inputs = useSelector((state)=>state.addLike.inputs);
    const inputs = {inputs: {
      commentID:0,
      like: "",
      userID:0
      }}
    const getCommentmemo = useMemo(()=>{
     
      return getComment(pid,props.Types);
    },[])
 
        const handlechange =  (event)=>{
      
          const name = event.target.name;
          const value = event.target.value;
          event.target.style.height = `24px`;
          event.target.style.height = `${event.target.scrollHeight}px`;

          setComment(event.target.value);
          //setInputs(values=>({...values,[name]:value}));
          
        }
        function clickreplies(event,commentID){
          event.preventDefault();
          const reply = false;
          const myNextList = [...contents];
          
          const replies = myNextList.find(
            a => a.commentID === commentID
          );
          replies.isReply = !replies.isReply;
          setContent(myNextList);
        
        }
        function insertUserID(){
          const name = "userID";
          const value = props.userID;
         
          //setInputs(values=>({...values,[name]:value}))
        }
        function insertPostID(){
          const name = "postID";
          const value = props.postID;
      
        //  setInputs(values=>({...values,[name]:value}))
        }
  
        function getComment(postID,Type){
         
          let axiosConfig = {
            headers: {
                'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      
            }
          };
        //  let url = link=="content"?`http://localhost/API/comments.php/${Type}`:`http://localhost/API/comments.php/${Type}/${Type}`;
          axios.get(`http://localhost/API/comments.php/${postID}/${Type}`,axiosConfig).then(function(response){
            console.log(response.data)
            const nextisreply = response.data.map((value,i)=>{
              
                const name = "isReply";
                const isreply = false;
                const namevallue = name + isreply;
                return {...value , [name]:isreply};
              
              
          });
          console.log(postID);
          setContent(nextisreply);
          
          });
          setCount(count+1);
          }

        
           function handleLike(event,commentID,like){
            event.preventDefault();
            setLike(!isLike);
            //dispatch(addLike({commentID:commentID,like:like,userID:props.userID}));
            inputs.commentID = commentID;
            inputs.like = like;
            inputs.userID = props.userID;
            let axiosConfig = {
              headers: {
                  'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        
              }
            };
           axios.post(`http://localhost/API/likes.php`, inputs,axiosConfig).then(function(response){
             
               
     
            
            });
            
            }
         
          function onFocustxtcomment(){
              setFocustxtcomment(true);
          }
          function onBlurtxtcomment(){
            setFocustxtcomment(false);
        }
            useEffect(() => {
                
                insertUserID();
                insertPostID();

                getComment(pid,props.Types);
             
            }, [props]);
   
            const  addReply = async (event,commentID,inputs)=>{
      
              event.preventDefault();
              let axiosConfig = {
                headers: {
                    'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
          
                }
              };
      
              axios.post(`http://localhost/API/replies.php/${commentID}`,inputs,axiosConfig).then(function(response){
             
              
              });
              setReload(!reload);
              navigate('/');
            }
  return (
      

        <Col className={styles.colCommentpros}  sm="12"  >
       {contents.map((content,key)=>
              {
                return(
        <Container key = {key} fluid="md"  className={styles.containerComment}>
        
            <Row  className={styles.rowComment}>
            <Col md="1" lg="2" className = {styles.colLogo}> 
                <Image rounded  src={userImage} className={styles.imgLogo}/>
                
                </Col>
                <Col md="5" lg="10" className = {styles.Colname}> 
                <Stack gap={0} >
                <Card.Text className={styles.cardText}>{content==[] ? null : content.firstname + " " +content.lastname}</Card.Text>
                <Card.Text className={styles.cardTextTime}>{content==[] ? null :content.timeago+" ago"}</Card.Text>
                </Stack>
                
                </Col>
            </Row>
            
            <Row >
            <Col md="12" className={styles.colContent}> 
            <label className={styles.cardCotent}>
                {content==[] ? null :content.comment}
                
                </label>
                
                </Col>
            </Row>
            <Row>
            <Col md="12" className={styles.colReplies}>
            <Stack gap={0} direction="horizontal" >
              <Commentlike userID = {props.userID} commentID = {content.commentID} type = {"comment"}/>
              <div className={styles.btnReplies}  onClick={event=>clickreplies(event,content.commentID)}>
      
              
                          <label className={styles.repliesText}>
                          
                          {content.isReply==true?<RiArrowUpSLine   className={styles.arrowDown} />:<RiArrowDownSLine  className={styles.arrowDown} /> }<RepliesCount commentID={content.commentID} /> Replies
                         
                          </label>  
                    
              </div>        
              </Stack>         
             </Col>
            </Row>
            <Row style={{display:content.isReply==true?"block":"none"}}>
           
        
          
            {/* Cons Replies */}
        
            <Replies type={props.Types} commentID={content.commentID} reload = {reload} userID = {props.userID} postID =  {props.postID} addreply = {addReply}/>
           
            {/* Cons Replies */}
            </Row>
              
        </Container>
              );
            }
                
            )
            }
        </Col>
    
  );
}

export default Comments;


import {createSlice} from '@reduxjs/toolkit';

export const postIDSlice = createSlice({
    name:'postID',
    initialState:{
        value:0
    },
    reducers:{
        addpostID(state,action){
            const {pID}  = action.payload
            state.value = pID
        },
       
    }
})
export const {addpostID} = postIDSlice.actions;
export default postIDSlice.reducer;


import {createSlice} from '@reduxjs/toolkit';
export const show = createSlice({

    name:'show',
    initialState:{
        value:false,
    },
    reducers:{
        increment:(state)=>{
            state.value=true
        },
        decrement:(state)=>{
            state.value =false
        },
        incrementByAmount:(state,action)=>{
            state.value = action.type
        }
    }
})

export const {increment , decrement , incrementByAmount} = show.actions
export default  show.reducer


import { useEffect, useState, useContext, useCallback } from 'react';


import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App";
import styles from './SaveButton.module.css';
import { FaRegBookmark } from "react-icons/fa";

import { FaBookmark } from "react-icons/fa";
function SaveButton(props) {

    const [isSave,setSave] = useState(false);
    const userID = props.userID;
    const postID = props.postID;
    const [count,setCount] = useState(0);
   // const inputs = {inputs: {
      //postID:0,
     // save: "",
      //userID:0
     // }}


    useEffect(()=>{
        saved(postID,userID);
    },[postID,isSave])
    /*function saved2(postID,userID) {

      let axiosConfig = {
          headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',

          }
      };
      axios.get(`http://localhost/API/savepost.php/${postID}/${userID}`,axiosConfig).then(function(response) {

        setSave(response.data>0);
        console.log(response.data+ " " +postID);

      });

  }*/
    const saved = useCallback(async(postID,userID)=>{
            
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        try{
          const response = await axios.get(`http://localhost/API/savepost.php/${postID}/${userID}`,axiosConfig);
       
        setSave(response.data>0);
        console.log(userID);
        
       
        }
        catch(error){
          console.log(error);
        }
          
        
     
        },[postID,isSave])

       const savePost = useCallback(async(event,postID,save)=>{
        event.preventDefault();
        setSave(!isSave);
        //dispatch(addLike({commentID:commentID,like:like,userID:props.userID}));
        //inputs.postID = postID;
        //inputs.save = save;
        //inputs.userID = userID;
        console.log(userID);
     
        let axiosConfig = {
          headers: {
              'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    
          }
        };
        try{
                const response = await axios.post(`http://localhost/API/savepost.php`, {
              postID,
              save,
              userID
              },axiosConfig); 
              console.log(response);
        }
        catch(error){
          console.log(error)
        }
        
        
      },[isSave])


    return (




        <>
         {isSave?<FaBookmark className={styles.iconSaved} onClick={event=>savePost(event,postID,false)}/>:<FaRegBookmark className={styles.icon} onClick={event=>savePost(event,postID,true)}/>}
        </>
        
              
               
          

    );
}

export default SaveButton;
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Div from 'react-bootstrap/Form';
import { Card } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import axios from "axios"
import { useEffect, useState } from "react";
import {useNavigate } from 'react-router-dom';
import Alert from 'react-bootstrap/Alert';
import PropTypes from "prop-types";
import  styles from './landingpage.module.css';
import Image from 'react-bootstrap/Image';
import  Logo from '../images/LOGO5.png';
import Login from './Login';
import Register from './Register';
import { TypeAnimation } from 'react-type-animation';
import { useGoogleLogin  } from '@react-oauth/google';
import { FcGoogle } from "react-icons/fc";
export default function LandingPage({setToken}) {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState([]);
    const [Loginseen, setloginSeen] = useState(false)
    const [Regseen, setregSeen] = useState(false)
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const responseMessage = (response) => {
        console.log(response);
    };
    const errorMessage = (error) => {
        console.log(error);
    };
    const login = useGoogleLogin({
        onSuccess: (codeResponse) => responseMessage,
        onError: (error) => errorMessage
    });

    function togglelogin () {
        setloginSeen(!Loginseen);
        setregSeen(false);
    };
    function togglereg () {
        setregSeen(!Regseen);
        setloginSeen(false);
    };
     
  return (
      <>
      <Container className = {styles.container} >
          {
              show &&
                <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                <Alert.Heading>Oh snap!!</Alert.Heading>
                <p>
                Wrong Email or Password.
                </p>
                </Alert>
              
          }
      
          <Row className="justify-content-md-center">
              
              <Col md="6">
              <Row >
             
              <Col  md="3" className={styles.divlogo}> 
              <Image  src={Logo} className={styles.logo} rounded/>
               
              </Col>
              <Col  md="3" className={styles.divhoozh}> 
                    <label className={styles.lblhoozh}>
                    hoozh
                    </label>     
              </Col>
              </Row>
              <Row className="justify-content-md-center">
              <Div  md="2" className={styles.divlabel1}> 
                    <label className={styles.label1}>
                    Join the Debate and Share Your Voice
                    </label>
              </Div>
              </Row>
              <Row className="justify-content-md-center">
              <Div  md="2" className={styles.divlabel2}> 
                    <label className={styles.label2}>
                    Discover a platform where you can engage in meaningful debates and connect with like-minded individuals.
                    </label>
              </Div>
              </Row>
              </Col>
              <Col md="6">
              <Row className="justify-content-md-start">
              <Div  md="2" className={styles.divarat}> 
                    <label className={styles.labelarat}>
                   <TypeAnimation
                    preRenderFirstString={true}
                    sequence={[
                     1000,
                    'Arat Na . . .', // initially rendered starting point
                    1000,
                    'Lets Go . . .',
                 
                    ]}
                    speed={20}
                  
                    repeat={Infinity}
                />
                    </label>
                        
              </Div>
              </Row>
              <Row className="justify-content-md-start">
              <Div  md="12" className={styles.divgoogle}> 
            
                    
             
                                <Button type="button" onClick={login}  className={styles.btnloginForm +' '+styles.btngoogle}>
                                <FcGoogle /> Sign In With Google
                               </Button>
                      
              </Div>
              </Row>
              <Row className="justify-content-md-start">
              <Div  md="12" className={styles.divcreateaccount}> 
            
                    <Button type="button" onClick={togglereg}  className={styles.btnloginForm +' '+styles.btnRegister}>
                            Create Account
                        </Button>
              
                        
              </Div>
              </Row>
              <Row className="justify-content-md-start">
              <Div  md="12" className={styles.divready}> 
            
              <label className={styles.labelalready}>
                Already have an account?
               </label>
               </Div>
               </Row>
               <Row className="justify-content-md-start">
            <Div  md="12" className={styles.divlogin}> 
            
            <Button type="button" onClick={togglelogin}  className={styles.btnloginForm +' '+styles.btnLogin}>
                    Sign In
                </Button>
      
                
            </Div>
     
              </Row>
              </Col>
          </Row>
       
      </Container>
      <Container>
   <Row className="justify-content-md-start">
    <Col  md="12"> 
    {Loginseen ? <Login toggle={togglelogin} setToken={setToken} /> : null}

    </Col>
    <Col  md="12"> 
    {Regseen ? <Register toggle={togglereg} /> : null}

    </Col>
    </Row>
      </Container>

</>

  );
}
LandingPage.prototype = {
    setToken:PropTypes.func.isRequired
}
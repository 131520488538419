import { createSlice } from '@reduxjs/toolkit';

export const showDeletePost = createSlice({
    name: 'deletepost',
    initialState: {
        value: false
    },
    reducers: {
        openDeletepost: (state) => {
            state.value = true
        },
        closeDeletepost: (state) => {
            state.value = false
        }
    }
})
export const { openDeletepost, closeDeletepost } = showDeletePost.actions;
export default showDeletePost.reducer;